import { __awaiter, __generator } from "tslib";
import { isVerticalLayoutMobile } from '../shared/selectors/app-settings';
import { getVideoIds } from '../widget/redux/client/lazy-channel-videos/selectors';
import { clearHydratedData } from '../widget/redux/client/hydrated-data/hydrated-data';
import { getPublicChannel } from '../widget/redux/server/actions/channel/get-public';
import { getPaymentInfo } from '../widget/redux/server/actions/channel/payment';
import { getMainVideoId } from '@wix/wix-vod-shared/dist/src/widget/ui-selectors/selected-video-id';
import { getVideoId } from '../shared/utils/app-settings';
import { loadMoreVideoPages, prependVideoIds, } from '../widget/redux/client/lazy-channel-videos/actions';
import { getPublicVideo } from '../widget/redux/server/actions/channel/video/get';
import { selectVideo } from '../widget/redux/client/actions/select-video';
import _ from 'lodash';
import { getVideoIdFromUrl } from '../widget/selectors/query-params';
import { getVideoById } from '../shared/selectors/videos';
import { openVideoPage } from '../widget/redux/client/actions/mobile-overlay-openners/main-openners';
import { getCurrentChannelId } from '../widget/selectors/channel-info';
export var setInitialState = function (isSSR) { return function (dispatch, getState) { return __awaiter(void 0, void 0, void 0, function () {
    var state, channelId, singleVideoId, videoIdFromUrl, pagesCount, videoIds, mainVideoId;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                state = getState();
                channelId = getCurrentChannelId(state);
                singleVideoId = getVideoId(state.appSettings);
                videoIdFromUrl = getVideoIdFromUrl(state);
                return [4 /*yield*/, Promise.all([
                        dispatch(getPublicChannel(channelId)),
                        dispatch(getPaymentInfo(channelId, isSSR)),
                    ])];
            case 1:
                _a.sent();
                if (!singleVideoId) return [3 /*break*/, 3];
                return [4 /*yield*/, dispatch(getPublicVideo(channelId, singleVideoId))];
            case 2:
                _a.sent();
                dispatch(prependVideoIds(singleVideoId));
                dispatch(selectVideo(singleVideoId));
                return [3 /*break*/, 5];
            case 3:
                pagesCount = isVerticalLayoutMobile(state) ? 2 : undefined;
                return [4 /*yield*/, dispatch(loadMoreVideoPages(pagesCount))];
            case 4:
                _a.sent();
                videoIds = getVideoIds(getState());
                mainVideoId = getMainVideoId(getState());
                if (!_.includes(videoIds, mainVideoId)) {
                    dispatch(prependVideoIds(mainVideoId));
                }
                _a.label = 5;
            case 5:
                if (!videoIdFromUrl) return [3 /*break*/, 7];
                return [4 /*yield*/, getPublicVideo(channelId, videoIdFromUrl)];
            case 6:
                _a.sent();
                _a.label = 7;
            case 7:
                dispatch(clearHydratedData());
                return [2 /*return*/];
        }
    });
}); }; };
export var openVideoFromUrlIfExists = function () { return function (dispatch, getState) {
    var state = getState();
    var videoIdFromUrl = getVideoIdFromUrl(state);
    var videoFromUrlExists = getVideoById(state, videoIdFromUrl);
    if (videoFromUrlExists) {
        dispatch(openVideoPage(videoIdFromUrl));
    }
}; };
